//export let authUrl = "http://test.aim.org/";
//export let riskUrl = "http://localhost:8001/";
//export let userUrl = "http://localhost:8002/";
//export let alertUrl = "http://localhost:8003/";
//export let taskUrl = "http://localhost:8004/";
//export let emailUrl = "http://localhost:8008/";

let base_url = window.location.origin;

export let authUrl = base_url + "/";
export let riskUrl = base_url + "/risk/";
export let userUrl = base_url + "/";
export let alertUrl = base_url + "/alert/";
export let taskUrl = base_url + "/task/";
export let emailUrl = base_url + "/email/";
